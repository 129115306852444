import { getBidAmountByStatus } from './auctionDetailsUtils';
import { getAssetsBaseURL } from './utils'

export const getImageLinks = (imageLink, data, slug) => {
  if (slug === 'au-infath' || slug === undefined) {
    return imageLink[0]?.split('/')[0] === 'https:'
      ? `${imageLink?.[0]}?w=360,quality=60,format=auto`
      : `${getAssetsBaseURL()}/properties/${data?.propertyId}/images/${encodeURI(
          imageLink?.[0],
        )}/width=360,quality=60,format=auto`
  }
  return `${getAssetsBaseURL()}/properties/${data?.propertyId}/images/${encodeURI(
    imageLink?.[0],
  )}/width=360,quality=60,format=auto`
}

export const convertSecondsToDhms = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return { days, hours, minutes, remainingSeconds };
};

export const fetchCurrentEpoch = async (setcurrentEpoch) => {
  try {
    const startTime = performance.now();
    const response = await fetch(`${process.env.NEXT_PUBLIC_URL_WASALT}get-server-time`);
    const data = await response.json();
    const endTime = performance.now();
    if(response?.status == 200) {
      const date = new Date(data?.serverTime);
      const currDate = new Date().getTime()
      setcurrentEpoch(currDate-date.getTime()-(endTime-startTime));
    }

  } catch (error) {
    throw new Error(`Failed to fetch ,error Status:${error}`)
  }
};

export const getPPSM = (setPPSM, newAuctionData, auctionGroupId) => {
  const wasaltAuctionBidValue = newAuctionData?.highestBid ?? newAuctionData?.bidStartingPrice
  const currentBid = auctionGroupId ? getBidAmountByStatus(newAuctionData) : wasaltAuctionBidValue
  const PPSM = newAuctionData?.landArea ? Number((currentBid) / Number(newAuctionData.landArea),).toFixed(2) : 0;
  if (isNaN(PPSM)) {
    return;
  }
		setPPSM(PPSM)
} 

export const syncPropertiesFromLocalStorage = (token, window, save) => {
  const store = JSON.parse(window.localStorage.getItem('propertyStore'));
  if (store) {
  if (store.favourites?.length) {
    save(token, 'saveAuction', 'saved_property', store.favourites);
  }
  if (store.seen?.length) {
    save(token, 'saveAuction', 'seen_property', store.seen);
  }
  if (store.contacted?.length) {
    save(token, 'saveAuction', 'contacted_property', store.contacted);
  }
  }
};

export const syncPropertiesFromCookies = (token, cookie, save) => {
  const propertyStore = cookie.get('propertyStore');
  if (propertyStore) {
    if (propertyStore.favourites?.length) {
    save(token, 'saveAuction', 'saved_property', propertyStore.favourites);
    }
    if (propertyStore.seen?.length) {
    save(token, 'saveAuction', 'seen_property', propertyStore.seen);
    }
    if (propertyStore.contacted?.length) {
    save(token, 'saveAuction', 'contacted_property', propertyStore.contacted);
    }
  }
};
