import CustomToast from "@/components/Common/CustomToast"
import { numberWithCommas, pushDataLayerEventHandler, pushEvent, sellerType, showCustomToast, showErrorToast, showSuccessToast } from "./utils"
import { fetchApi } from "./apiCall"

/**
 * Author: Mitesh
 * The function to log socket events.
*/
export const socketLogging = async ({ type, isMobile, lang, firstName=null, end_date=null, serverTime=null }) => {
    if(process.env.NEXT_PUBLIC_DISABLE_SOCKET_LOGGING === "true") {
        return false
    }
    let socketData = {}
    if (firstName) {
        socketData['firstName'] = firstName
    }
    if (end_date) {
        socketData['end_date'] = end_date
    }
    if (serverTime) {
        socketData['serverTime'] = serverTime
    }

    const reqParams = {
        platform: isMobile ? 'm-site' : 'web',
        uniqueId: localStorage.getItem('systemId'),
        socketType: type,
        socketData: JSON.stringify(socketData)
    }

    const URLParams = new URLSearchParams(reqParams).toString()
    try {
        await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/socketlogging?${URLParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                locale: lang,
                Cookie: `i18next=${lang}`,
                authorization: window.localStorage.getItem('authToken') ?? '',
            },
        })
    } catch (e) {
        console.error(e);
    }
}

const handleSuccessPayment = async ({
    auctionData,
    setShowConfirmation,
    setLoading,
    getAuctionHistory,
    getAuctionDetails,
    setOpenPrimaryPopup,
    lang,
    auctionDetails,
    postPlacingBid
}) => {
    setShowConfirmation(false);
    getAuctionHistory(0, 7);
    setLoading(false);

    if (auctionData.status === 'upcoming') {
        await getAuctionDetails();
        setOpenPrimaryPopup(true);
        pushEvent(window, lang, 'Register_Success', 'Registration Flow', auctionData, auctionDetails);
    }

    if (auctionData.status === 'live') {
        pushEvent(window, lang, 'Register_Success', 'PlaceBid Flow', auctionData, auctionDetails);

        const currentHighestBid = auctionData?.highestBid || auctionData?.bidStartingPrice;
        let currentSlab = auctionData.AuctionSlabs.find(
            ({ startBid, endBid }) => currentHighestBid >= startBid && currentHighestBid <= endBid
        );

        currentSlab = currentSlab ? currentSlab.bidIncrement : Number(auctionData.maximumBidIncrement);
        const totalPlaceBid = Number(currentHighestBid) + Number(currentSlab);

        auctionData.isRegistered = true;
        auctionData.transactionStatus = 'on_hold';

        setTimeout(() => {
            postPlacingBid(totalPlaceBid, auctionData);
        }, 1000);
    }
};

export const handleFailurePayment = ({
    response,
    auctionData,
    setShowConfirmation,
    setLoading,
    lang,
    auctionDetails
}) => {
    if (auctionData.status === 'upcoming') {
        pushEvent(window, lang, 'Payment_Failure', 'Registration Flow', auctionData, auctionDetails);
    } else if (auctionData.status === 'live') {
        pushEvent(window, lang, 'Payment_Failure', 'PlaceBid Flow', auctionData, auctionDetails);
    }

    setShowConfirmation(false);
    showErrorToast(response.data.message);
    setLoading(false);
};

export const getPaymentStatus = async ({
    auctionData,
    setLoading,
    setShowConfirmation,
    router,
    lang,
    getAuctionHistory,
    setOpenPrimaryPopup,
    auctionDetails,
    postPlacingBid,
    getAuctionDetails
}) => {
    setLoading(true);
    setTimeout(() => {
        setShowConfirmation(true);
    }, 250);

    const newData = {
        checkoutId: router?.query?.id,
        auctionId: router?.query?.auctionId,
        entityName: router?.query?.cardBrand,
    };

    try {
        const res = await fetch(`/api/payment?apiUrl=payment-status`, {
            method: 'POST',
            headers: {
                authorization: localStorage.getItem('authToken') ?? '',
                'cache-control': 'no-cache',
                'Content-Type': 'application/json',
                locale: lang,
                Cookie: `i18next=${lang}`,
            },
            body: JSON.stringify({ ...newData }),
        });

        const response = await res.json();
        router.push(router.asPath.replace(/\?.+/, ''));

        if (response.data.status) {
            await handleSuccessPayment({ auctionData, setShowConfirmation, setLoading, getAuctionHistory, getAuctionDetails, setOpenPrimaryPopup, lang, auctionDetails, postPlacingBid });
        } else {
            handleFailurePayment({response, auctionData, setShowConfirmation, setLoading, lang, auctionDetails});
        }
    } catch (error) {
        console.error(error);
        setLoading(false);
    }
};


/**
 * Author: Mitesh
 * The function to generate bread crumb title for group auction details.
*/
export const gdpBreadcrumbsData = (data, lang) => ({
	label: data?.title,
	url: lang == 'ar' ? `/auction-group/${data.auctionId}` : `/en/auction-group/${data.auctionId}`,
})


export const breadcrumbsData = (data, t, lang) => {
	const { city, propertyFor, title, cityId } = data
	const array = [
		{
			label: t('auctionCommon.AUCTION'),
			url: lang == 'ar' ? '/' : '/en/',
		},
	]
	if (city && propertyFor) {
		array.push({
			label: city,
			url: lang == 'ar' ? `/?cityId=${cityId}` : `/en?auction_seller=${sellerType.WASALT}&cityId=${cityId}`,
		})
	}
	if (title) array.push({ label: title })
	return array
}

export const onUpdateData = (newData, setData) => {
    setData((currentData) => {
        let selectedEndDate;
        if (currentData?.endDate) {
            selectedEndDate = (currentData.endDate > newData?.endDate) ? currentData.endDate : newData.endDate;
        } else {
            selectedEndDate = newData.endDate;
        }

        let selectedServerTimeExtension;
        if (newData?.serverTimeOnExtension && currentData?.serverTimeOnExtension) {
            selectedServerTimeExtension =  newData.serverTimeOnExtension > currentData.serverTimeOnExtension ? newData.serverTimeOnExtension : currentData.serverTimeOnExtension;
        } else {
            selectedServerTimeExtension = currentData?.serverTimeOnExtension;
        }

        if(typeof currentData?.serverTimeOnExtension === 'undefined' || !currentData?.serverTimeOnExtension) {
            selectedServerTimeExtension = newData?.serverTimeOnExtension
        }
        return ({...newData, endDate: selectedEndDate, serverTimeOnExtension: selectedServerTimeExtension})
    })
}

// Function to show bid amount based on auction status
export const getBidAmountByStatus = (resData) => {
    const { bidStartingPrice, highestBid, status } = resData ?? {}
    const commissionTopSectionData = {
        live: { amount: highestBid ? highestBid : bidStartingPrice },
        upcoming: { amount: bidStartingPrice ? bidStartingPrice : null },
        past: { amount: highestBid ? highestBid : bidStartingPrice },
        cancel: { amount: highestBid ? highestBid : bidStartingPrice },
    }
    return commissionTopSectionData[status]?.amount
}

export const manageAuction = async ({socketData, getAuctionDetails, setData, setAuctionDetails, auctionDetails, visibleHistory, userData, t, setShowClosedPopup, setIsInfathSuccessClosedPopupOpen, setShowWinnerPopup, setShowCancelledPopup}) => {
    const updatedAuctionData =  await getAuctionDetails()
    setData({
        ...updatedAuctionData
    })

    setAuctionDetails({
        ...auctionDetails,
        status: updatedAuctionData?.status,
        endDate: updatedAuctionData?.endDate,
        startDate: updatedAuctionData?.startDate,
        serverTime: updatedAuctionData?.serverTime,
    })

    if ( window.localStorage.getItem('userId') && window.localStorage.getItem('authToken') && socketData?.status?.toLowerCase() == 'past' && updatedAuctionData.isRegistered) {
        handleAuctionCompletion({
            visibleHistory,
            userData,
            updatedAuctionData,
            auctionDetails,
            t,
            setShowClosedPopup,
            setIsInfathSuccessClosedPopupOpen,
            setShowWinnerPopup,
            setShowCancelledPopup
        });
    } 
}

const handleAuctionCompletion = ({visibleHistory, userData, updatedAuctionData, auctionDetails, t, setShowClosedPopup,
setIsInfathSuccessClosedPopupOpen, setShowWinnerPopup, setShowCancelledPopup}) => {
    if ( visibleHistory?.length > 0 &&
        visibleHistory[0].phoneNumber == (userData?.updatedAuctionData?.phoneNumber || userData?.data?.phoneNumber) &&
        visibleHistory[0].amount >= updatedAuctionData.reservedPrice
            ) {
                auctionDetails?.isInfathAuction ? setIsInfathSuccessClosedPopupOpen(true) : setShowWinnerPopup(true)
            } else {
                let message = ''
                let highestBid = updatedAuctionData.highestBid ? updatedAuctionData.highestBid : updatedAuctionData.bidStartingPrice
                if (highestBid >= updatedAuctionData.reservedPrice) {
                    message = t('Details.AUCTION_CLOSED_PARTICIPATION')
                } else {
                    message = t('Details.CLOSED_AUCTION_MESSAGE')
                }
                setShowClosedPopup(message)
            }
}

export const handleEvent = (lang, event, data, auctionDetails, extraData={}) => {
    pushEvent(window, lang, event, 'ADP', data, auctionDetails, {
        ...extraData,
        source: 'registration',
    })
}

export const handleAuctionGroupIdCheck = (auctionGroupId, data, displayTabs, isMobile, isPastAuction=false) => {
    if (auctionGroupId) {
        return isPastAuction ? data.status === 'past' : data.status !== 'past';
    }
    const minTabs = isMobile ? 0 : 1;
    return displayTabs.length > minTabs && (isPastAuction ? data.status == 'past' : data.status !== 'past');
};

export const onNotifyMe = ({userData, auctionDetails, lang, token, setShowNotifyPopup, router, data, setOpenPrimaryPopup, setShowLoginPopup, setData, userDataRes=null, auctionData=null}) => {
    if (window.localStorage.getItem('authToken') && window.localStorage.getItem('userId')) {
        let isAbsherVerified = userDataRes ? userDataRes.data.nationalityId : userData?.data?.nationalityId
        if (isAbsherVerified) {
            auctionRegistration({auctionDetails, lang, token, auctionData, data, setData, setShowNotifyPopup, router})
        } else {
            setTimeout(() => {
                setOpenPrimaryPopup(true)
            }, 250)
        }
    } else {
        setShowLoginPopup(true)
    }
}

const auctionRegistration = async ({auctionDetails, lang, token, auctionData, data, setData, setShowNotifyPopup, router}) => {
    let objectToSend = {
        userId: localStorage.getItem('userId'),
        entityId: auctionDetails.auctionId,
        type: 'auction_registration_open',
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_URL}saveAuction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            locale: lang,
            cookie: `i18next=${lang}`,
            authorization: token,
        },
        body: JSON.stringify(objectToSend),
    })
    const response = await res.json()
    if (response.status === true) {
        let data1 = auctionData ? { ...auctionData, isAuctionNotified: true } : { ...data, isAuctionNotified: true }
        onUpdateData(data1, setData)
        setShowNotifyPopup(true)
        if (router.query.login) {
            router.push(router.asPath.replace(/\?.+/, ''))
        }
        window.localStorage.setItem('notify', auctionData ? auctionData.auctionId : data.auctionId)
    } else if (response.status === false) {
        showErrorToast(response.message.error.message)
    }
}

// This function plays an audio notification after a bid is placed.
const playSound = async (alertSound) => {
    alertSound.stop(); // stopping previous sound if there
    alertSound.play();
};

export const resetBid = ({bidHistoryData, data, visibleHistory, alertSound, setVisibleHistory, setAuctionHistoryData, setAuctionHistory, UpdateAuctionData, userData, openPrimaryPopup, showConfirmation, showHistory, loading, showDepositPopup, t, lang, setShowDepositPopup, setBidHistoryData, isTimerOver, setIsTimerOver}) => {
    if(bidHistoryData && bidHistoryData?.bid !== data?.highestBid){
      
        let historyData = [...visibleHistory]
        let bid = data?.highestBid ? data.highestBid : data?.bidStartingPrice
        if(!document.hidden){
            playSound(alertSound)
        }
        if (!(visibleHistory.length > 0 && bidHistoryData?.bid?.amount == visibleHistory[0].amount)) {
            historyData = setHistoryVisibility(bidHistoryData, visibleHistory)
            setVisibleHistory(historyData);
        }
  
        if (parseInt(bidHistoryData?.bid?.amount) >= bid) {
            updateAuctionHistoryData({bidHistoryData, setAuctionHistoryData, setAuctionHistory, UpdateAuctionData, data, userData, openPrimaryPopup, showConfirmation, showHistory, loading, showDepositPopup, t, lang, setShowDepositPopup, historyData})
        }
        if (userData?.data?.phoneNumber != historyData?.[0]?.phoneNumber && !document.hidden) {
            setTimeout(() => {
                showCustomToast(
                        <CustomToast
                            showIcon='new_bid'
                            headerText={t('Details.NEW_BID_RECIEVED')}
                            descriptiveText={`${t('Details.NEW_BID_SAR')} ${numberWithCommas(bidHistoryData.bid.amount)} ${t(
                                'Details.RECIEVED_BY',
                            )} ${bidHistoryData.bid.firstName ?? bidHistoryData.bid.fullName}`}
                        />,
                    'new-bid',
                    lang
                )
            }, 500)
        }
        setBidHistoryData(null)
    }
    // Here we are checking if auction is still live or not and updating timeOver state.
    checkAuctionLiveStatus(data, isTimerOver, setIsTimerOver)
}

const checkAuctionLiveStatus = (data, isTimerOver, setIsTimerOver) => {
    if (isTimerOver?.live) {
        const isAuctionStillLive = data?.status === 'live' && new Date(data?.serverTime) < new Date(data?.endDate);
        setIsTimerOver({
            upcoming: false,
            live: !isAuctionStillLive,
        });
    }
};

const updateAuctionHistoryData = ({bidHistoryData, setAuctionHistoryData, setAuctionHistory, UpdateAuctionData, data, userData, openPrimaryPopup, showConfirmation, showHistory, loading, showDepositPopup, t, lang, setShowDepositPopup, historyData}) => {
            setAuctionHistoryData(bidHistoryData)
            setAuctionHistory(bidHistoryData.bid)
            if(!data?.extendedTimeInMills) {
                // update auction data
                const auctionTime = {
                    serverTime: bidHistoryData?.serverTime, 
                    endDate: bidHistoryData?.auctionEndDate,
                    leftOverMs: bidHistoryData?.leftOverMs,
                    leftOverServerMs: bidHistoryData?.leftOverServerMs
                }
                UpdateAuctionData(
                    bidHistoryData.bid.amount,
                    auctionTime,
                    {...data, activeBidder: bidHistoryData?.activeBidder, status: 'live'},
                )
            }
    if (userData?.data.phoneNumber != bidHistoryData.bid.phoneNumber && !document.hidden) {
        let isAnyPopUpActive = openPrimaryPopup || showConfirmation || showHistory || loading
        if (!isAnyPopUpActive && !document.hidden) {
            if (showDepositPopup) {
                showCustomToast(
                        <CustomToast
                            showIcon='warning'
                            headerText={t('Details.OOPS')}
                            descriptiveText={`${t('auctionPopup.BID_DIDNT_GO_THROUGH')}`}
                        />,
                    'oops',
                    lang
                )
                setShowDepositPopup(false)
            }
            if (historyData?.length > 1 && userData?.data?.phoneNumber && userData?.data?.phoneNumber == historyData[1]?.phoneNumber) {
                showCustomToast(
                        <CustomToast
                            showIcon='error'
                            headerText={t('Details.OUTBID')}
                            descriptiveText={`${t('auctionPopup.YOU_HAVE_BEEN_OUTBID')}`}
                        />,
                    'outbid',
                    lang
                )
            }
        }
    }
}

const setHistoryVisibility = (bidHistoryData, visibleHistory) => {
    const newEntry = {
        ...bidHistoryData?.bid,
        amount: Number(bidHistoryData?.bid?.amount),
        serverTime: bidHistoryData?.serverTime,
        auctionEndDate: bidHistoryData?.auctionEndDate,
    };

    let historyData = [newEntry, ...visibleHistory];

    // Limit the history to the latest 7 entries and sort by amount in descending order
    historyData = historyData
        .slice(0, 7)
        .sort((a, b) => b.amount - a.amount);

    // Update the visible history
    return historyData
};

export const fetch_Amenities_Fittings_StreetInfo = async (router, setAmenities_Fittings_StreetInfo) => {
    const response = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_API_URL_V3',
        subUrl: `/property-amenities/${router.query['auction-id']}?type=auction`,
    })
    if (response?.data?.status) {
        setAmenities_Fittings_StreetInfo(response.data.data)
    }
}

// Function to fetch amenities, fittings, and street info
export const fetchAmenitiesFittingsStreetInfo = async (router, setAmenitiesFittingsStreetInfo) => {
    const response = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_API_URL_V3',
        subUrl: `/property-amenities/${router.query['auction-id']}?type=auction`,
    });

    if (response?.data?.status) {
        setAmenitiesFittingsStreetInfo(response.data.data);
    }
};

// Function to save auction data
const saveAuctionData = async (auctionData, lang, auctionDetails) => {
    if (!auctionDetails?.isInfathAuction) {
        await fetchApi({
            lang,
            baseUrl: 'NEXT_PUBLIC_AUCTION_API_URL_V2',
            subUrl: `/saveAuction`,
            method: 'POST',
            reqBody: {
                entityIds: [
                    {
                        id: auctionData?.auctionId,
                        propIdentityType: 95,
                    },
                ],
                type: 'seen_auction',
            },
        });
    }
};

// Function to handle login scenarios
const handleLoginScenarios = async ({router, auctionData, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowNotifyPopup, setShowLoginPopup, token, userData, data, setData}) => {
    if (router.query.login === 'true') {
        if (auctionData.status === 'upcoming') {
            handleUpcomingAuctionLogin({router, auctionData, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowNotifyPopup, setShowLoginPopup, token, userData, data, setData});
        } else if (auctionData.status === 'live') {
            handleLiveAuctionLogin(router, auctionData, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowDepositPopup);
        }
    }
};

// Function to handle upcoming auction login
const handleUpcomingAuctionLogin = ({router, auctionData, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowNotifyPopup, setShowLoginPopup, token, userData, data, setData}) => {
    if (!auctionData.isAuctionNotified && !auctionData.auctionRegistrationDetails.isRegistrationOpen) {
        pushEvent(window, lang, 'NotifyMe_Login_Success', 'NotifyMe Flow', auctionData, auctionDetails);
        onNotifyMe({ userData, auctionDetails, lang, token, setShowNotifyPopup, router, data, setOpenPrimaryPopup, setShowLoginPopup, setData, userDataRes, auctionData});
    } else if (!auctionData.isRegistered && auctionData.auctionRegistrationDetails.isRegistrationOpen) {
        pushEvent(window, lang, 'Register_Login_Success', 'Registration Flow', auctionData, auctionDetails);
        if (userDataRes?.data?.nationalityId) {
            getEntity(auctionData);
        } else {
            setOpenPrimaryPopup(true);
        }
    }
};

// Function to handle live auction login
const handleLiveAuctionLogin = (router, auctionData, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowDepositPopup) => {
    pushEvent(window, lang, 'PlaceBid_Login_Success', 'PlaceBid Flow', auctionData, auctionDetails);
    if (!auctionData.isRegistered) {
        if (userDataRes?.data?.nationalityId) {
            getEntity(auctionData);
        } else {
            setOpenPrimaryPopup(true);
        }
    } else if (auctionData.isRegistered) {
        router.push(router.asPath.replace(/\?.+/, ''));
        setShowDepositPopup(true);
    }
};

// Function to handle payment status
const handlePaymentStatus = ({ router, auctionData, setLoading, setShowConfirmation, lang, getAuctionHistory, setOpenPrimaryPopup, auctionDetails, postPlacingBid, getAuctionDetails }) => {
    if (router.query.cardBrand) {
        getPaymentStatus({ auctionData, setLoading, setShowConfirmation, router, lang, getAuctionHistory, setOpenPrimaryPopup, auctionDetails, postPlacingBid, getAuctionDetails });
    } else {
        getAuctionHistory(0, 7);
    }
};

// Function to handle Absher data
const handleAbsherData = async ({ router, auctionData, auth, lang, t, setUserData, auctionDetails, userData, token, setShowNotifyPopup, data, setOpenPrimaryPopup, setShowLoginPopup , setData}) => {
    if (router.query.data) {
        const absherDecodeData = decodeURI(router.query.data);
        const absherJsonData = JSON.parse(absherDecodeData);
        absherJsonData.dob_gregorian = absherJsonData.dob;
        absherJsonData.userId = window.localStorage.getItem('userId');
        absherJsonData.nationalityId = absherJsonData.nationalityCode;
        delete absherJsonData.nationalityCode;

        await PostAbhserData({ auth, absherJsonData, auctionData, lang, t, router, setUserData, auctionDetails, userData, token, setShowNotifyPopup, data, setOpenPrimaryPopup, setShowLoginPopup , setData});

        if (auctionData.status === 'upcoming' && !auctionData.isAuctionNotified && !auctionData.auctionRegistrationDetails.isRegistrationOpen) {
            return;
        } else {
            setOpenPrimaryPopup(true);
        }
    }
};

// Main function to fetch API data
export const fetchAPI = async ({ getAuctionDetails, setGuestUserActivityList, setToken, setUserId, lang, auctionDetails, setUserData, router, setOpenPrimaryPopup, setShowNotifyPopup, setShowLoginPopup, token, userData, data, setLoading, setShowConfirmation, getAuctionHistory, postPlacingBid, t, isMobile, isTablet, setData}) => {
    const auth = window.localStorage.getItem('authToken');
    const auctionData = await getAuctionDetails();

    setGuestUserActivityList('seen', auctionData?.auctionId);
    setToken(auth);
    setUserId(window.localStorage.getItem('userId'));

    await saveAuctionData(auctionData, lang, auctionDetails);
    pushEvent(window, lang, 'Auction_Details_Page_Open', 'ADP', auctionData, auctionDetails);

    let userDataRes = null;
    if (auth) {
        userDataRes = await getUserDetails(lang, setUserData);
    }

    handleLoginScenarios({router, auctionData, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowNotifyPopup, setShowLoginPopup, token, userData, data, setData});
    handlePaymentStatus({ router, auctionData, setLoading, setShowConfirmation, lang, getAuctionHistory, setOpenPrimaryPopup, auctionDetails, postPlacingBid, getAuctionDetails });
    await handleAbsherData({ router, auctionData, auth, lang, t, setUserData, auctionDetails, userData, token, setShowNotifyPopup, data, setOpenPrimaryPopup, setShowLoginPopup, setData});

    const createScrollHandler = () => {
        return () => handleScroll(isMobile, isTablet);
    };

    window.addEventListener('scroll', createScrollHandler());
    return () => {
        window.removeEventListener('scroll', createScrollHandler());
    };
};

const PostAbhserData = async ({auth, absherJsonData, auctionData, lang, t, router, setUserData, auctionDetails, userData, token, setShowNotifyPopup, data, setOpenPrimaryPopup, setShowLoginPopup, setData}) => {
    const res = await fetch(`/api/absher`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            locale: lang,
            Cookie: `i18next=${lang}`,
            authorization: auth,
        },
        body: JSON.stringify({ ...absherJsonData }),
    })
    const response = await res.json()
    const responseData = await response.data
    if (responseData?.status) {
        showSuccessToast(t('auctionBidComponent.IAM_VERIFIED_SUCCESSFULL'),"customClass")
        pushDataLayerEventHandler(window, lang, 'iam_verified_successfully', 'adp', {})
        router.push(router.asPath.replace(/\?.+/, ''))
        const user = await getUserDetails(lang, setUserData)
        if (auctionData.status == 'upcoming') {
            if (!auctionData?.isAuctionNotified && !auctionData?.auctionRegistrationDetails?.isRegistrationOpen) {
                pushEvent(window, lang, 'NotifyMe_KYC_Success', 'NotifyMe Flow', auctionData, auctionDetails)
                onNotifyMe({userData, auctionDetails, lang, token, setShowNotifyPopup, router, data, setOpenPrimaryPopup, setShowLoginPopup, setData, user, auctionData})
            } else {
                pushEvent(window, lang, 'Register_KYC_Success', 'Registration Flow', auctionData, auctionDetails)
            }
        } else if (auctionData.status == 'live') {
            pushEvent(window, lang, 'PlaceBid_KYC_Success', 'PlaceBid Flow', auctionData, auctionDetails)
        }
    } else if (responseData?.status === false) {
        showErrorToast(t('auctionBidComponent.SOMETHING_WENT_WRONG_IAM'))
    }
}

export const getUserDetails = async (lang, setUserData) => {
    if (!localStorage.getItem('authToken')) {
        return
    }
    const res = await fetch(`/api/gateway?apiUrl=user/profile`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            locale: lang,
            Cookie: `i18next=${lang}`,
            authorization: window.localStorage.getItem('authToken') ?? '',
        },
    })
    const response = await res.json()
    setUserData(response.data)
    return response.data
}

const greenStripClose = () => {
    if (document.getElementById('green_strip')) {
        document.getElementById('green_strip').style.display = 'none'
    }
}

const handleScroll = (isMobile, isTablet) => {
    if (window.scrollY >= 800 && !isTablet && !isMobile) {
        greenStripClose()
    }
    if (window.scrollY >= 700 && isTablet) {
        greenStripClose()
    }
    if (window.scrollY >= 600 && isMobile) {
        greenStripClose()
    }
}